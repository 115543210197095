import { useEffect, useState } from "react"
import useConversation from "../../zustand/useConversation";
import toast from "react-hot-toast"
import axios from "axios";

const useGetMessages = () => {
    const [ loading, setLoading ] = useState(false);
    const { messages, setMessages, selectedConversation } = useConversation();

    useEffect(() => {
        const getMessagesApi = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`/api/messages/${selectedConversation._id}`)
                if (data.error) throw new Error(data.error);
                setMessages(data);
            } catch (error) {
                toast.error(error.message);
            } finally {
                setLoading(false);
            }
        }

        if(selectedConversation?._id) getMessagesApi();
    },[selectedConversation?._id, setMessages]);

    return { messages, loading };
}

export default useGetMessages;